<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <router-link v-if="is_API_loaded" :to="{ name: 'new-page' }">
        <vs-button size="large"><span class="font-medium">新增分頁</span></vs-button>
      </router-link>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <vs-input class="w-full" v-model="searchTitle" @blur="search" @keypress.enter="search" placeholder="搜尋分頁" />
        <vs-select style="max-width: 200px" multiple filter placeholder="篩選狀態" v-model="filtered" @change="statusOptions">
          <vs-option label="公開" value="1">已公開</vs-option>
          <vs-option label="隱藏" value="0">已隱藏</vs-option>
        </vs-select>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ pages.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="batchPublic(1)" class="px-4 hover:bg-gray-background font-medium">公開</button>
                <button @click="batchPublic(0)" class="px-4 hover:bg-gray-background font-medium">隱藏</button>
                <button @click="deletePages_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title">分頁標題</span>
              <span class="w-36">分頁狀態</span>
              <span style="width: 187px">最後修改</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in pages" :key="item.spno">
            <router-link
              :to="{
                name: 'edit-page',
                params: {
                  id: item.spno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <!-- 列表個別的checkbox -->
              <div class="checkbox_section">
                <!-- type = 0 正常的checkbox-->
                <vs-checkbox v-if="item.type === 0" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.spno"></vs-checkbox>
                <!-- type = 1 顯示鎖頭 -->
                <svg-icon v-else icon-class="lock" className="gray-lightest"></svg-icon>
              </div>

              <div class="title_section">
                <span class="title text-limit-1">{{ item.title }}</span>
                <div class="w-36">
                  <span v-if="item.isrtime" class="bg-primary text-white rounded-full px-3 py-1">已公開</span>
                  <span v-else-if="!item.isrtime && isSchedule(item.rtime)" class="bg-warning text-white rounded-full px-3 py-1"
                    >排程中</span
                  >
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">已隱藏</span>
                </div>
                <span style="width: 187px">{{ item.udate }}</span>
              </div>
              <!-- 預覽分頁按鈕 -->
              <a
                @click.stop=""
                :href="item.mpages"
                target="_blank"
                class="
                  hidden
                  lg:block
                  absolute
                  right-3
                  top-1/2
                  transform
                  -translate-y-1/2
                  transition-all
                  opacity-0
                  group-hover:opacity-100
                  invisible
                  group-hover:visible
                  hover:bg-gray-divide
                  rounded-lg
                "
              >
                <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
              </a>
              <!-- <div
                @click.stop="preview"
                class="
                  absolute
                  right-3
                  top-1/2
                  transform
                  -translate-y-1/2
                  transition-all
                  opacity-0
                  group-hover:opacity-100
                  invisible
                  group-hover:visible
                  hover:bg-gray-divide
                  rounded-lg
                "
              >
                <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
              </div> -->

              <!-- <div
                v-if="item.isread_num > 0"
                @click.stop=""
                class="absolute left-16 top-1/2 transform -translate-y-1/2 hover:bg-gray-divide rounded-lg"
              >
                <vs-tooltip danger>
                  <svg-icon icon-class="message" className="danger xl"></svg-icon>
                  <template #tooltip>此分頁有 {{ item.isread_num }} 筆未讀留言</template>
                </vs-tooltip>
              </div> -->
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除分頁 dialog -->
    <vs-dialog overflow-hidden blur v-model="deletePages_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆分頁？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的分頁無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePages_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deletePagesBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'pages',
  data() {
    return {
      is_API_loaded: false, // 判斷並呈現骨架屏
      searchTitle: '',
      searchTitleBak: '',
      filtered: [],
      selectAll: false,
      indeterminate: false,
      selected: [],
      pages: [],
      pagination: {},
      currentPage: 1,
      deletePages_dialog: false, // 刪除分頁 dialog
      defaultQuery: {},
    }
  },
  computed: {
    _isrtime() {
      let filtered = this.filtered

      if (filtered.includes('1') && filtered.includes('0')) {
        return -1
      } else if (filtered.includes('1')) {
        return 1
      } else if (filtered.includes('0')) {
        return 0
      } else {
        return -1
      }
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  async created() {
    let query = this.$route.query
    this.defaultQuery = query

    this.searchTitle = query.search || ''
    this.filtered = query.status?.split(',') || []
    this.currentPage = parseInt(query.page) || 1

    this.loadPages()
  },
  methods: {
    // 判斷是不是排程中
    isSchedule(rtime) {
      let time = new Date(rtime.replace(new RegExp('-', 'gm'), '/')).getTime()

      // 時間若小於 2099/12/31 23:59:00
      if (time < 4102415940000) {
        return true
      } else {
        return false
      }
    },
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.loadPages([['page', this.currentPage]])
    },
    // 篩選狀態時觸發
    statusOptions(e) {
      this.loadPages([
        ['page', 1],
        ['status', this.filtered.toString()],
      ])
    },
    // 篩選標題時觸發
    search(e) {
      // 若一樣，不執行搜尋
      if (this.searchTitle === this.searchTitleBak) return

      this.loadPages([
        ['page', 1],
        ['search', this.searchTitle],
      ])

      this.currentPage = 1
      this.searchTitleBak = this.searchTitle
    },
    // 2.4.1 網站分頁列表-讀取
    loadPages(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '' || routeQuery[key] === -99) {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      this.$axios({
        url: 'front/pages/onepages/getPagesList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
          page: routeQuery.page || 1,
          title: routeQuery.search || '',
          isrtime: this._isrtime,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.pages = res.data.Data
          this.pagination = res.data.Page
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 公開/隱藏
    batchPublic(isrtime) {
      this.$axios({
        url: 'front/pages/onepages/uPagesRtime.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this._selectedToStr,
          isrtime: isrtime, // 0 隱藏 1 公開
        },
      }).then((res) => {
        if (res.data.Success) {
          this.loadPages([['page', this.currentPage]])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deletePagesBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/onepages/dPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.deletePages_dialog = false // 關閉dialog
          this.currentPage = 1
          this.loadPages([['page', 1]])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        // 抓出可以刪掉的分頁
        let type0pages = this.pages.filter((item) => item.type === 0)

        if (this.selected.length > 0 && this.selected.length < type0pages.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === type0pages.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        let type0pages = this.pages.filter((item) => item.type === 0)

        type0pages.forEach((page) => this.selected.push(page.spno))
        // this.selected.push(...type0pages.spno)
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}
</style>
